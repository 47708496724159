<template>
    <section class="app-ecommerce-details">
        <!-- Alert: No item found -->

        <b-overlay :show="formShow" rounded="sm" no-fade>
            <b-row>
                <b-col cols="12">
                    <b-card no-body>
                        <b-card-body>
                            <b-row class="my-2">
                                <profile-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />

                                <b-col cols="2">
                                    <b-img-lazy rounded thumbnail fluid v-bind="mainProps" class="d-inline-block mr-1" style="width: 100%" ref="refPreviewEl" :src="imageUrl" />

                                    <b-button v-show="$Can('client_upload_btn')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" @click="$refs.refInputEl.click()" block variant="primary">
                                        Upload
                                    </b-button>
                                </b-col>

                                <input ref="refInputEl" type="file" class="d-none" accept="image/*" @input="imageSelected" />

                                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                                </b-col>

                                <b-col cols="10">
                                    <b-row class="justify-content-between">
                                        <h1 class="ml-1">{{ clientData.name }}</h1>

                                        <div>
                                            <b-button v-show="$Can('client_activate_btn')" class="mr-1" v-if="clientData.status != 'active'" @click="activateClient" variant="success">
                                                Activate
                                            </b-button>

                                            <b-button v-show="$Can('client_remove_btn')" class="mr-1" v-if="clientData.status != 'deleted'" @click="removeClient" variant="danger">
                                                Remove
                                            </b-button>

                                            <b-button v-show="$Can('client_edit_btn')" class="mr-1" @click="editClient" variant="primary">
                                                Edit
                                            </b-button>
                                        </div>
                                    </b-row>

                                    <table class="mt-2 table  table-bordered table-hover table-sm">
                                        <tbody>
                                            <tr>
                                                <th class="w-25 bg-primary text-white" scope="row">
                                                    Role
                                                </th>
                                                <td class="w-75">
                                                    {{ clientData.role }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" class="bg-primary text-white">
                                                    Email
                                                </th>
                                                <td>
                                                    {{ clientData.email }}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row" class="bg-primary text-white">
                                                    Status
                                                </th>
                                                <td>
                                                    <template>
                                                        <b-badge v-if="clientData.status == 'active'" pill :variant="`light-success`" class="text-capitalize">
                                                            Active
                                                        </b-badge>

                                                        <b-badge v-if="clientData.status == 'passive'" pill :variant="`light-warning`" class="text-capitalize">
                                                            Inactive
                                                        </b-badge>

                                                        <b-badge v-if="clientData.status == 'deleted'" pill :variant="`danger`" class="text-capitalize">
                                                            Removed
                                                        </b-badge>
                                                    </template>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>

                    <b-card>
                        <b-tabs vertical nav-wrapper-class="nav-vertical" pills class="tab-class">
                            <b-tab active title="General Information" v-if="$Can('client_information_tab')">
                                <b-card-body class="mt-0 pt-0">
                                    <b-row>
                                        <table class=" table  table-bordered table-hover">
                                            <tbody class="text-left">
                                                <tr>
                                                    <td class="item-price bg-secondary text-white w-25">Client Name</td>
                                                    <td class="w-75">{{ clientData.name }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Contact Person</td>
                                                    <td>{{ clientData.contact_person }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Phone</td>
                                                    <td>{{ clientData.phone }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Email</td>
                                                    <td>{{ clientData.email }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Phone</td>
                                                    <td>{{ clientData.phone }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Role</td>
                                                    <td>{{ clientData.role }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">VAT Number</td>
                                                    <td>{{ clientData.vat_number }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Address</td>
                                                    <td>{{ clientData.address1 }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">City</td>
                                                    <td>{{ clientData.city }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-row>
                                </b-card-body>
                            </b-tab>

                            <b-tab title="Files" v-if="$Can('client_files_tab')">
                                <b-card-body class="mt-0 pt-0">
                                    <b-row>
                                        <b-button v-show="$Can('client_files_add_btn')" class="btn-icon ml-auto  mt-1 mb-1" @click="addNewFile()" variant="primary">
                                            Add New File
                                        </b-button>

                                        <table class=" table  table-bordered table-hover table-sm ">
                                            <b-col cols="12" v-if="clientData.id != null">
                                                <!-- user suggestion  -->
                                                <div v-for="(data, index) in files" :key="index" class="ml-5 mr-5 mt-2 mb-2">
                                                    <div v-if="data.status == 'A'" class="d-flex justify-content-between align-items-center">
                                                        <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                                        <div class="user-page-info pr-1" style="width: 100%">
                                                            <span @click="fileEdit(data)" style="cursor:pointer; font-size:1.1em" class="mb-2">{{ data.fileName }} </span>
                                                        </div>

                                                        <b-button
                                                            variant="primary"
                                                            target="_blank"
                                                            :href="fileURL + data.fileLink"
                                                            style="cursor:pointer;    margin-right:2px"
                                                            class="btn-icon ml-auto mr-1"
                                                            size="sm"
                                                        >
                                                            <feather-icon icon="DownloadIcon" />
                                                        </b-button>

                                                        <b-button variant="danger" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                                            <feather-icon icon="XIcon" />
                                                        </b-button>
                                                    </div>

                                                    <div v-else class="d-flex justify-content-between align-items-center">
                                                        <b-img src="@/assets/images/url.png" @click="linkEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                                                        <div class="user-page-info pr-1" style="width: 100%">
                                                            <span @click="linkEdit(data)" style="cursor:pointer; font-size:1.1em">{{ data.fileName }} </span>
                                                        </div>
                                                        <b-button
                                                            variant="primary"
                                                            target="_blank"
                                                            :href="'https://' + data.fileLink"
                                                            style="cursor:alias;  margin-right:2px"
                                                            class="btn-icon ml-auto mr-1"
                                                            size="sm"
                                                            v-b-tooltip.hover
                                                            title="Go to url adress"
                                                        >
                                                            <feather-icon icon="ExternalLinkIcon" />
                                                        </b-button>

                                                        <b-button variant="danger" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                                                            <feather-icon icon="XIcon" />
                                                        </b-button>
                                                    </div>
                                                    <hr />
                                                </div>

                                                <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
                                                    No Files
                                                </div>
                                            </b-col>
                                        </table>
                                    </b-row>
                                </b-card-body>
                            </b-tab>

                            <b-tab title="Events" v-if="$Can('client_events_tab')">
                                <b-card no-body class="mb-0">
                                    <b-overlay rounded="sm" no-fade>
                                        <div class="m-2">
                                            <!-- Table Top -->
                                        </div>

                                        <b-table
                                            striped
                                            hover
                                            class="position-relative"
                                            :style="clientData.event_list.length < 3 ? 'height: 500px' : ''"
                                            :items="clientData.event_list"
                                            selectable
                                            select-mode="single"
                                            responsive
                                            :small="true"
                                            head-variant="dark"
                                            :fields="tableColumnsEvents"
                                            show-empty
                                            empty-text="No matching records found"
                                            @row-selected="onEventRowSelected"
                                            :current-page="currentPageEvent"
                                            per-page="15"
                                        >
                                            <!-- Column: Id -->
                                            <template #cell(id)="data">
                                                <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
                                                <strong class="text-primary">#{{ data.value }}</strong>
                                            </template>

                                            <!-- Column: Name -->
                                            <template #cell(eventDate)="data">
                                                {{ data.value }}
                                            </template>

                                            <!-- Column: Status -->
                                            <template #cell(invoiceStatus)="data">
                                                <b-badge v-if="data.value" pill :variant="`light-success`" class="text-capitalize">
                                                    Invoiced
                                                </b-badge>

                                                <b-badge v-else pill :variant="`light-danger`" class="text-capitalize">
                                                    Not Invoiced
                                                </b-badge>
                                            </template>

                                            <!-- Column: Actions -->
                                        </b-table>

                                        <div class="mx-2 mb-2">
                                            <b-row>
                                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                                    <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                                                </b-col>

                                                <!-- Pagination -->
                                                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                                                    <b-pagination
                                                        v-model="currentPageEvent"
                                                        :total-rows="clientData.event_list.length"
                                                        per-page="15"
                                                        first-number
                                                        last-number
                                                        class="mb-0 mt-1 mt-sm-0"
                                                        prev-class="prev-item"
                                                        next-class="next-item"
                                                    >
                                                        <template #prev-text>
                                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                                        </template>
                                                        <template #next-text>
                                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                                        </template>
                                                    </b-pagination>
                                                </b-col>
                                            </b-row>
                                        </div>
                                    </b-overlay>
                                </b-card>
                            </b-tab>

                            <b-tab title="Rates" v-if="$Can('client_rates_tab')">
                                <b-card-body class="mt-0 pt-0">
                                    <b-row>
                                        <table class=" table  table-bordered table-hover">
                                            <tbody class="text-left">
                                                <tr>
                                                    <th colspan="2" class="w-50">Foreman Rates</th>
                                                    <th colspan="2" class="w-50">Tent Builder Rates</th>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white" style="width:15%;">Day Rate UK</td>
                                                    <td style="width:35%;">{{ clientData.f_day_rate ? $func.formatPrice(clientData.f_day_rate) : "Not Available" }}</td>
                                                    <td style="width:15%;" class="item-price bg-secondary text-white">Day Rate UK</td>
                                                    <td style="width:35%;">{{ clientData.day_rate ? $func.formatPrice(clientData.day_rate) : "Not Available" }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Weekend Rate UK</td>
                                                    <td>{{ clientData.f_weekend_rate ? $func.formatPrice(clientData.f_weekend_rate) : "Not Available" }}</td>
                                                    <td class="item-price bg-secondary text-white">Weekend Rate UK</td>
                                                    <td>{{ clientData.weekend_rate ? $func.formatPrice(clientData.weekend_rate) : "Not Available" }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Day Rate EU</td>
                                                    <td>{{ clientData.f_day_rate_eu ? $func.formatPrice(clientData.f_day_rate_eu) : "Not Available" }}</td>
                                                    <td class="item-price bg-secondary text-white">Day Rate EU</td>
                                                    <td>{{ clientData.day_rate_eu ? $func.formatPrice(clientData.day_rate_eu) : "Not Available" }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Weekend Rate EU</td>
                                                    <td>{{ clientData.f_weekend_rate_eu ? $func.formatPrice(clientData.f_weekend_rate_eu) : "Not Available" }}</td>
                                                    <td class="item-price bg-secondary text-white">Weekend Rate EU</td>
                                                    <td>{{ clientData.weekend_rate_eu ? $func.formatPrice(clientData.weekend_rate_eu) : "Not Available" }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Day Rate Global</td>
                                                    <td>{{ clientData.f_day_rate_glo ? $func.formatPrice(clientData.f_day_rate_glo) : "Not Available" }}</td>
                                                    <td class="item-price bg-secondary text-white">Day Rate Global</td>
                                                    <td>{{ clientData.day_rate_glo ? $func.formatPrice(clientData.day_rate_glo) : "Not Available" }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Weekend Rate Global</td>
                                                    <td>{{ clientData.f_weekend_rate_glo ? $func.formatPrice(clientData.f_weekend_rate_glo) : "Not Available" }}</td>
                                                    <td class="item-price bg-secondary text-white">Weekend Rate Global</td>
                                                    <td>{{ clientData.weekend_rate_glo ? $func.formatPrice(clientData.weekend_rate_glo) : "Not Available" }}</td>
                                                </tr>

                                                <tr>
                                                    <td class="item-price bg-secondary text-white">Overtime (per hour)</td>
                                                    <td>{{ clientData.f_overtime ? $func.formatPrice(clientData.f_overtime) : "Not Available" }}</td>
                                                    <td class="item-price bg-secondary text-white">Overtime (per hour)</td>
                                                    <td>{{ clientData.overtime ? $func.formatPrice(clientData.overtime) : "Not Available" }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </b-row>
                                </b-card-body>
                            </b-tab>

                            <b-tab title="Timeline" v-if="$Can('client_timeline_tab')">
                                <b-card v-if="clientData.timeline ? clientData.timeline.length : 0 > 0" style="overflow-y:scroll; height:500px">
                                    <app-timeline class="mt-2">
                                        <app-timeline-item v-for="item in clientData.timeline" :key="item.id" :variant="$func.varyant(item.status)" :icon="$func.varicon(item.status)">
                                            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                                                <h6>{{ item.title }}</h6>
                                                <small class="text-muted">{{ $func.dateFormatTimeline(item.created_at) }}</small>
                                            </div>
                                            <small>{{ "By " + item.userNameSurname }}</small>
                                        </app-timeline-item>
                                    </app-timeline>
                                </b-card>
                            </b-tab>
                        </b-tabs>
                    </b-card>
                </b-col>

                <!-- <b-col cols="12" xl="2" md="3">
          <b-card>
            <b-card class="user-title" v-for="user in clients" :key="user.id">
              <div @click="viewClient(user.id)">
                <span class="user-title"> {{ user.name }}</span>
              </div>
            </b-card>
          </b-card>
        </b-col> -->
            </b-row>
            <b-modal
                id="modal-file"
                cancel-variant="outline-secondary"
                ok-title="Save File"
                @ok="handleOk"
                @show="resetModal"
                @hidden="resetModal"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Add New File"
            >
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>
                    <b-form-group>
                        <b-form-checkbox class="custom-control-primary" name="check-button" switch inline v-model="selectFileType">
                            <span class="switch-icon-left">
                                <feather-icon icon="FileIcon" />
                            </span>
                            <span class="switch-icon-right">
                                <feather-icon icon="LinkIcon" />
                            </span>
                        </b-form-checkbox>
                    </b-form-group>
                    <b-form-group :state="fileState" invalid-feedback="File is required" v-if="selectFileType">
                        <b-form-file
                            @input="fileSelected"
                            type="file"
                            :state="fileState"
                            required
                            placeholder="Choose a file or drop it here..."
                            drop-placeholder="Drop file here..."
                            class="animate__animated animate__fadeIn"
                        />
                    </b-form-group>
                    <b-form-group :state="linkState" invalid-feedback="Link is required" v-else>
                        <b-form-input :state="linkState" id="basicInput" v-model="fileLink" required placeholder="Enter Link" class="animate__animated animate__fadeIn" />
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>

            <b-modal
                id="modal-file-update"
                cancel-variant="outline-secondary"
                ok-title="Change File"
                @ok="handleUpdate"
                cancel-title="Close"
                :no-close-on-backdrop="true"
                centered
                title="Add New File"
            >
                <b-form ref="fileRules">
                    <b-form-group :state="nameState" invalid-feedback="File Name is required">
                        <label for="fileName">File Name:</label>
                        <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
                    </b-form-group>
                </b-form>
                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                    <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>
            </b-modal>
        </b-overlay>
    </section>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
("bootstrap-vue");
import { BModal, BAvatar, BCard, BCardBody, BRow, BCol, BImg, BLink, BButton, BAlert, BCardHeader, BCardTitle, VBToggle, BTabs, BTab, BCardText, BProgress } from "bootstrap-vue";
import moment from "moment";
import clientStoreModule from "../clientStoreModule";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axiosIns from "@/libs/axios";
import ProfileCropper from "@core/components/image-cropper/ProfileCropper.vue";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

// import { avatarText } from '@core/utils/filter'

export default {
    directives: {
        Ripple,
        "b-toggle": VBToggle,
    },

    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BCard,
        BButton,
        BAvatar,
        BModal,
        BCardBody,
        BImg,
        BCardHeader,
        BCardTitle,
        BTabs,
        BCardText,
        BTab,
        ProfileCropper,
        BProgress,
        AppTimeline,
        AppTimelineItem,
    },
    setup() {
        const CLIENT_APP_STORE_MODULE_NAME = "client";

        // Register module
        if (!store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.registerModule(CLIENT_APP_STORE_MODULE_NAME, clientStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CLIENT_APP_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_APP_STORE_MODULE_NAME);
        });
    },

    data: function() {
        var clientData = {
            name: null,
            contact_person: null,
            email: null,
            phone: null,
            address1: null,
            city: null,
            vat_number: null,
            colour: "",
            textColour: "",
            password: null,
            profile: null,
            event_list: [],
        };

        return {
            clientData: clientData,
            baseURL: store.state.app.baseURL,
            imageUrl: store.state.app.profileURL + "none.jpg",
            tempImage: null,
            profileURL: store.state.app.profileURL,
            selectedImage: null,
            croppedImage: null,
            userData: {},
            percentCompleted: 0,
            roleOptions: [],

            clientId: null,
            formShow: false,
            permissions: [],
            clients: [],

            availability: "A",

            startDate: null,
            endDate: null,
            rangeDate: null,

            fetchAvailability: [],

            //FILES
            where: router.currentRoute.name,
            files: [],
            selectFileType: true,
            fileLink: null,
            modalId: null,
            modalFileName: null,
            modalFile: null,
            download: false,
            fileURL: store.state.app.fileURL,
            percentCompleted: 0,

            fetchTeams: [],
            totalRows: 0,

            options: {
                number: {
                    numeral: true,
                    numeralThousandsGroupStyle: "thousand",
                },

                percent: {
                    numeral: true,
                    numeralPositiveOnly: true,
                    blocks: [2],
                    prefix: "% ",
                    rawValueTrimPrefix: true,
                    numeralIntegerScale: 2,
                },
            },

            config: {
                dateFormat: "d-m-Y",
                mode: "range",
            },

            mainProps: {
                blank: true,
                blankColor: "#777",
                width: 75,
                height: 75,
                class: "m1",
            },
            currentPageEvent: 1,
            tableColumns: [
                { key: "availability", sortable: true, class: "text-center" },
                { key: "startDate", sortable: true, class: "text-center" },
                { key: "endDate", sortable: true, class: "text-center" },
            ],
            tableColumnsEvents: [
                { key: "id", sortable: true, class: "text-center" },
                { key: "eventDate", sortable: false, class: "text-center" },
                { key: "eventReference", sortable: false, class: "text-center" },
                { key: "location", sortable: false, class: "text-center" },

                { key: "vendorReference", label: "PO Number", sortable: false, class: "text-center" },
                { key: "invoiceNo", sortable: false, class: "text-center" },
                { key: "invoiceStatus", sortable: false, class: "text-center" },
            ],
            teamTableColumns: [
                { key: "id", sortable: true, class: "text-center" },
                { key: "title", sortable: true, class: "text-center" },
                { key: "foreman", sortable: true, class: "text-center" },
                { key: "staff_count", sortable: true, class: "text-center" },
                { key: "day_rate", sortable: true, class: "text-center" },
                { key: "status", sortable: true, class: "text-center" },
            ],
        };
    },

    created() {
        this.getClientById();
    },

    methods: {
        editClient() {
            router.push({ name: "client-edit", params: { id: router.currentRoute.params.id } });
        },

        viewClient(id) {
            this.formShow = true;
            store
                .dispatch("client/fetchClient", { id: id })
                .then((response) => {
                    this.clientData = response.data;
                    this.files = response.data.get_files;
                    this.clients = response.data.userList;
                    this.imageUrl = this.profileURL + response.data.profile;

                    this.formShow = false;
                })
                .catch((error) => {
                    console.log(error);
                    this.formShow = false;
                });
            router.push({ name: "client-view", params: { id: id } });
        },

        activateClient() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "Yes, activate the client!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "A",
                        where: "client",
                        userId: router.currentRoute.params.id,
                    };
                    this.clientData.status = "active";

                    store.dispatch("client/userStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ User Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                            this.getClientById();
                        }
                    });
                }
            });
        },

        terminateClient() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "question",
                showCancelButton: true,
                confirmButtonText: "Yes, terminate the client!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "P",
                        where: "client",
                        userId: router.currentRoute.params.id,
                    };
                    this.clientData.status = "passive";

                    store.dispatch("client/userStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ User Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                            this.getClientById();
                        }
                    });
                }
            });
        },

        removeClient() {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, remove the client!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    var tempData = {
                        status: "D",
                        where: "client",
                        userId: router.currentRoute.params.id,
                    };

                    this.clientData.status = "deleted";

                    store.dispatch("client/userStatus", tempData).then((response) => {
                        if (response.status == 200) {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: "Successful",
                                    text: "✔️ User Update Successful",
                                    icon: "ThumbsUpIcon",
                                    variant: "success",
                                },
                            });
                            this.getClientById();
                        }
                    });
                }
            });
        },

        getClientById() {
            this.formShow = true;

            if (router.currentRoute.params.id) {
                this.clientId = parseInt(router.currentRoute.params.id);

                store
                    .dispatch("client/fetchClient", { id: router.currentRoute.params.id })
                    .then((response) => {
                        if (response.status == 200) {
                            this.clientData = response.data;
                            this.files = response.data.get_files;
                            this.clients = response.data.userList;
                            this.clientData.event_list = response.data.event_list;
                            this.imageUrl = this.profileURL + response.data.profile;

                            this.formShow = false;
                        } else {
                            router.push({ name: "error-404" });
                        }
                    })
                    .catch((error) => {
                        router.push({ name: "error-404" });
                    });
            } else {
                this.formShow = false;
            }
        },

        dateFormat(val) {
            moment.locale("en-US");

            return moment(val, "YYYY-MM-DD").format("DD/MM/YYYY");
        },

        onTeamRowSelected(item) {
            router.push({ name: "team-edit", params: { id: item[0].id } });
        },

        onEventRowSelected(item) {
            router.push({ name: "event-view", params: { id: item[0].id } });
        },

        fileEdit(data) {
            this.modalId = data.id;
            this.modalFileName = data.fileName;
            this.$bvModal.show("modal-file-update");
        },

        linkEdit(data) {
            this.modalId = data.id;
            this.modalFileName = data.fileName;
            this.$bvModal.show("modal-file-update");
        },

        deleteFile(data) {
            this.$swal({
                title: "Are you sure?",
                text: "",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, remove it!",
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-danger ml-1",
                },
                buttonsStyling: false,
            }).then((result) => {
                if (result.value) {
                    this.formShow = true;

                    axiosIns
                        .delete(`deleteClientFile/${data.id}`)
                        .then((res) => {
                            var tempList = [];
                            this.files.forEach((element) => {
                                if (element.id != data.id) {
                                    tempList.push(element);
                                }
                            });

                            this.files = tempList;
                            this.formShow = false;
                            this.getClientById();
                        })
                        .catch((error) => {
                            this.$swal({
                                title: "Error",
                                text: "File could not be deleted. Please try again or contact support.",
                                icon: "error",
                                showCancelButton: false,
                                confirmButtonText: "OK",
                            });

                            console.log("error delete ", error);
                        });
                }
            });
        },

        addNewFile() {
            this.$bvModal.show("modal-file");
        },

        handleOk(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();

            if (this.selectFileType) {
                if (this.percentCompleted == 100) {
                    var data = {
                        user_id: this.clientData.id,
                        fileName: this.modalFileName,
                        fileLink: this.modalFile,
                        status: "A",
                    };

                    // Trigger submit handler
                    this.modalOkFile(data);
                }
            } else {
                var data = {
                    user_id: this.clientData.id,
                    fileName: this.modalFileName,
                    fileLink: this.fileLink,
                    status: "B",
                };
                this.modalOkFile(data);
            }
        },

        handleUpdate(bvModalEvent) {
            // Prevent modal from closing
            bvModalEvent.preventDefault();
            // Trigger submit handler
            this.modalUpdate();
        },

        resetModal() {
            this.modalFileName = null;
            this.modalFile = null;
        },

        modalOkFile(data) {
            if (!this.checkFormValidity()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-file");
            });

            this.formShow = true;
            axiosIns
                .post("saveClientFile", data)
                .then((response) => {
                    this.files.push({
                        id: response.data.id,
                        fileName: response.data.fileName,
                        fileLink: response.data.fileLink,
                        status: response.data.status,
                    });

                    this.selectFileType = true;
                    this.fileLink = null;
                    this.modalFileName = null;
                    this.modalFile = null;

                    this.formShow = false;
                    this.getClientById();
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        modalUpdate(val) {
            if (!this.checkFormValidity()) {
                return;
            }

            this.$nextTick(() => {
                this.$bvModal.hide("modal-file-update");
            });

            this.formShow = true;

            var data = {
                id: this.modalId,
                fileName: this.modalFileName,
                fileLink: this.modalFile,
            };

            axiosIns
                .put("updateClientFile", data)
                .then((response) => {
                    this.modalFileName = null;
                    this.modalFile = null;

                    this.files.forEach((file) => {
                        if (file.id == this.modalId) {
                            file.fileName = response.data.fileName;
                        }
                    });

                    this.formShow = false;
                    this.getClientById();
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        fileSelected(event) {
            //  this.formShow = true;
            let formData = new FormData();
            formData.append("file", event);

            const config = {
                onUploadProgress: function(progressEvent) {
                    this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                }.bind(this),
            };

            axiosIns
                .post("uploadClientFile", formData, config)
                .then((response) => {
                    this.modalFile = response.data;

                    // this.tempImage = null;
                    // this.selectedImage = null;
                    this.formShow = false;
                })
                .catch((error) => {
                    this.formShow = false;
                    console.log(error);
                });
        },

        checkFormValidity() {
            const nameValid = this.$refs.fileRules.checkValidity();
            const fileValid = this.$refs.fileRules.checkValidity();

            return fileValid && nameValid;
        },

        imageUpload() {},
        imageSelected(event) {
            const file = event.target.files ? event.target.files[0] : null;
            if (file != null) {
                this.getBase64(file).then((data) => {
                    this.selectedImage = data;
                });
            }
        },
        imageCropped(croppedImage) {
            if (croppedImage != null) {
                this.formShow = true;
                let formData = new FormData();
                formData.append("id", this.clientData.id);
                formData.append("file", croppedImage);
                const config = {
                    onUploadProgress: function(progressEvent) {
                        this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    }.bind(this),
                };
                axiosIns
                    .post("uploadPreviewPhoto", formData, config)
                    .then((response) => {
                        this.clientData.profile = response.data;
                        this.imageUrl = this.profileURL + response.data;

                        this.tempImage = null;
                        this.selectedImage = null;
                        this.formShow = false;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "Upload Successful",
                                text: "✔️ Image Cropped Successfully",
                                icon: "ThumbsUpIcon",
                                variant: "success",
                            },
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        this.formShow = false;
                        this.$swal({
                            title: "Error",
                            text: "Image upload not successful. Please try again or contact support.",
                            icon: "error",
                            showCancelButton: false,
                            confirmButtonText: "OK",
                        });
                    });
            } else {
                this.tempImage = null;
                this.selectedImage = null;
            }
        },
        getBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
    },

    computed: {
        dataMeta() {
            return {
                from: this.clientData.event_list.length != 0 ? 15 * (this.currentPageEvent - 1) + 1 : 0,
                to: 15 * (this.currentPageEvent - 1) + Number(15) < this.clientData.event_list.length ? 15 * (this.currentPageEvent - 1) + Number(15) : this.clientData.event_list.length,
                of: this.clientData.event_list.length,
            };
        },

        nameState() {
            return this.modalFileName != null && this.modalFileName != "" ? true : false;
        },

        fileState() {
            return this.modalFile != null && this.modalFile != "" ? true : false;
        },
        linkState() {
            let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            let regex = new RegExp(expression);
            let link = this.fileLink;
            if (link != null) {
                if (link.match(regex)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },

    // setup() {
    //   const refInputEl = ref(null);
    //   const previewEl = ref(null);
    //   const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);
    //   return {
    //     refInputEl,
    //     previewEl,
    //     inputImageRenderer,
    //   };
    // },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
@import "@core/scss/vue/libs/swiper.scss";
@import "~swiper/css/swiper.css";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.icon {
    stroke: black;
    fill: none;
    width: 36px;
    height: 36px;
    stroke-width: 0.6666666666666666;
}

.user-title {
    width: 100%;
    height: 100%;

    font-weight: bold;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    margin: auto;
}

.user-badge {
    text-align: center;
    vertical-align: middle;

    width: 50%;
    align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
    position: relative;
    display: flex;
    justify-content: start;
}

.tab-class >>> .nav-vertical {
    padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
    width: 100%;
}
</style>
